import React from 'react';
import PropTypes from 'prop-types';

const Radio = ({ id, label, register, value }) => (
  <div className="align-items-center d-flex form-check mb-3">
    <input className="form-check-input" id={id} type="radio" value={value} {...register} />
    <label className="form-check-label ps-2 text-0_875" htmlFor={id}>
      {label}
    </label>
  </div>
);

Radio.propTypes = {
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  register: PropTypes.object.isRequired,
};

export default Radio;
