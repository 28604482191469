import PropTypes from 'prop-types';
import { combineReducers } from 'redux';
import { normalize } from 'normalizr';
import schema from 'state/schema';
import { payments } from 'libs';
import { actionTypes as invitations } from './invitations';
import { actionTypes as brokerages } from './brokerages';

const CANCEL = 'lenderspotlight/users/CANCEL';
const CANCEL_FAILED = 'lenderspotlight/users/CANCEL_FAILED';
const CANCEL_SUCCESS = 'lenderspotlight/users/CANCEL_SUCCESS';
const FETCH = 'lenderspotlight/users/FETCH';
const FETCH_FAILED = 'lenderspotlight/users/FETCH_FAILED';
const FETCH_SUCCESS = 'lenderspotlight/users/FETCH_SUCCESS';
const FETCH_ME_SUCCESS = 'lenderspotlight/users/FETCH_ME_SUCCESS';
const RESUME = 'lenderspotlight/users/RESUME';
const RESUME_FAILED = 'lenderspotlight/users/RESUME_FAILED';
const RESUME_SUCCESS = 'lenderspotlight/users/RESUME_SUCCESS';
const SUBSCRIBE = 'lenderspotlight/users/SUBSCRIBE';
const SUBSCRIBE_FAILED = 'lenderspotlight/users/SUBSCRIBE_FAILED';
const SUBSCRIBE_SUCCESS = 'lenderspotlight/users/SUBSCRIBE_SUCCESS';
const START_FREE_TRIAL = 'lenderspotlight/users/START_FREE_TRIAL';
const START_FREE_TRIAL_FAILED = 'lenderspotlight/users/START_FREE_TRIAL_FAILED';
const START_FREE_TRIAL_SUCCESS = 'lenderspotlight/users/START_FREE_TRIAL_SUCCESS';
const SWITCH = 'lenderspotlight/users/SWITCH';
const SWITCH_FAILED = 'lenderspotlight/users/SWITCH_FAILED';
const SWITCH_SUCCESS = 'lenderspotlight/users/SWITCH_SUCCESS';
const UPDATE = 'lenderspotlight/users/UPDATE';
const UPDATE_FAILED = 'lenderspotlight/users/UPDATE_FAILED';
const UPDATE_SUCCESS = 'lenderspotlight/users/UPDATE_SUCCESS';
const UPDATE_PAYMENT = 'lenderspotlight/users/UPDATE_PAYMENT';
const UPDATE_PAYMENT_FAILED = 'lenderspotlight/users/UPDATE_PAYMENT_FAILED';
const UPDATE_PAYMENT_SUCCESS = 'lenderspotlight/users/UPDATE_PAYMENT_SUCCESS';
const UPLOAD = 'lenderspotlight/users/UPLOAD';
const UPLOAD_FAILED = 'lenderspotlight/users/UPLOAD_FAILED';
const UPLOAD_SUCCESS = 'lenderspotlight/users/UPLOAD_SUCCESS';

/**
 * Action Types
 * @type {Object}
 */
export const actionTypes = {
  CANCEL,
  CANCEL_FAILED,
  CANCEL_SUCCESS,
  FETCH,
  FETCH_FAILED,
  FETCH_SUCCESS,
  FETCH_ME_SUCCESS,
  RESUME,
  RESUME_FAILED,
  RESUME_SUCCESS,
  SUBSCRIBE,
  SUBSCRIBE_FAILED,
  SUBSCRIBE_SUCCESS,
  START_FREE_TRIAL,
  START_FREE_TRIAL_FAILED,
  START_FREE_TRIAL_SUCCESS,
  SWITCH,
  SWITCH_FAILED,
  SWITCH_SUCCESS,
  UPDATE,
  UPDATE_FAILED,
  UPDATE_SUCCESS,
  UPDATE_PAYMENT,
  UPDATE_PAYMENT_FAILED,
  UPDATE_PAYMENT_SUCCESS,
  UPLOAD,
  UPLOAD_FAILED,
  UPLOAD_SUCCESS,
};

/**
 * PropTypes Validation
 * @type {Function}
 */
export const propTypes = PropTypes.shape({
  cancelSubscription: PropTypes.func,
  fetchMe: PropTypes.func,
  resumeSubscription: PropTypes.func,
  subscribe: PropTypes.func,
  switchSubscription: PropTypes.func,
  update: PropTypes.func,
  updatePassword: PropTypes.func,
  updatePaymentCard: PropTypes.func,
  uploadPhoto: PropTypes.func,
});

/**
 * Hydrate Initial State global LS object.
 * @type {Object}
 */
let initialState = {};

if (window.__INITIALSTATE__ && window.__INITIALSTATE__.brokerages) {
  const data = normalize(window.__INITIALSTATE__.brokerages, [schema.brokerage]);

  initialState = {
    ...initialState,
    ...data.entities.users,
  };
}

/**
 * ById User Reducer
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
function byIdReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.users,
      };

    case brokerages.REMOVE_USER:
      return Object.keys(state).reduce((accum, userId) => {
        if (action.payload.toString() === userId.toString()) return accum;
        return { ...accum, [userId]: state[userId] };
      }, {});

    default:
      return state;
  }
}

/**
 * Current User Reducer
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
function currentUserReducer(state = window.__INITIALSTATE__.user, action) {
  switch (action.type) {
    case CANCEL_SUCCESS:
    case FETCH_ME_SUCCESS:
    case RESUME_SUCCESS:
    case SUBSCRIBE_SUCCESS:
    case START_FREE_TRIAL_SUCCESS:
    case SWITCH_SUCCESS:
    case UPDATE_SUCCESS:
    case invitations.ACCEPT_SUCCESS:
    case invitations.DECLINE_SUCCESS:
      return action.payload;

    case UPLOAD:
      return {
        ...state,
        old_photo: state.photo_url,
        photo_url: action.payload,
      };

    case UPLOAD_FAILED:
      return {
        ...state,
        photo_url: state.old_photo,
      };

    case brokerages.UPDATE_SUCCESS: {
      const clone = { ...state };
      clone.brokerage.name = action.payload.brokerageName;
      return clone;
    }
    default:
      return state;
  }
}

/**
 * IsLoading Reducer
 * @param {Boolean} state
 * @param {Object} action
 * @return {Boolean}
 */
function isLoadingReducer(state = false, action) {
  switch (action.type) {
    case FETCH:
    case UPDATE:
    case UPDATE_PAYMENT:
      return true;

    case FETCH_FAILED:
    case FETCH_SUCCESS:
    case UPDATE_FAILED:
    case UPDATE_SUCCESS:
    case UPDATE_PAYMENT_FAILED:
    case UPDATE_PAYMENT_SUCCESS:
      return false;

    default:
      return state;
  }
}

/**
 * IsUploading Reducer
 * @param {Boolean} state
 * @param {Object} action
 * @return {Boolean}
 */
function isUploadingRecucer(state = false, action) {
  switch (action.type) {
    case UPLOAD:
      return true;

    case UPLOAD_FAILED:
    case UPLOAD_SUCCESS:
      return false;

    default:
      return state;
  }
}

/**
 * Export Lender Reducer
 * @type {Object}
 */
export default combineReducers({
  byId: byIdReducer,
  currentUser: currentUserReducer,
  isLoading: isLoadingReducer,
  isUploading: isUploadingRecucer,
});

/**
 * Fetch success action creator.
 * @param {Object} response
 * @return {Void}
 */
function fetchSuccess(response) {
  const payload = normalize(response.data, [schema.user]);
  return { type: FETCH_SUCCESS, payload };
}

/**
 * Update User Failed Reponse Action
 * @param {Error} error
 * @return {Object}
 */
function updateFailed(error) {
  const payload = error.response.data.errors;
  return { type: UPDATE_FAILED, error, payload };
}

/**
 * Update User Success Action
 * @param {Object} response
 * @return {Object}
 */
function updateSuccess(response) {
  return { type: UPDATE_SUCCESS, payload: response.data };
}

/**
 * Cancel Subscription Thunk
 * @param {String} subscriptionName
 * @return {Function}
 */
export function cancelSubscription(subscriptionName) {
  return (dispatch) => {
    dispatch({ type: CANCEL });
    return axios
      .patch('/api/subscription/cancel', { subscriptionName })
      .then((response) => dispatch({ type: CANCEL_SUCCESS, payload: response.data }))
      .catch((error) => dispatch({ type: CANCEL_FAILED, error }));
  };
}

/**
 * Fetch users by brokerageId.
 * @param {Number} brokerageId
 * @return {Function}
 */
export function fetchByBrokerageId(brokerageId) {
  return (dispatch) => {
    dispatch({ type: FETCH });
    return axios
      .get(`/api/brokerages/${brokerageId}/users`)
      .then((response) => dispatch(fetchSuccess(response)))
      .catch((error) => dispatch({ type: FETCH_FAILED, error }));
  };
}

/**
 * Fetch Current User Thunk
 * @return {Promise}
 */
export function fetchMe() {
  return (dispatch) => {
    dispatch({ type: FETCH });
    return axios
      .get('/api/me')
      .then((response) => dispatch({ type: FETCH_ME_SUCCESS, payload: response.data }))
      .catch((error) => dispatch({ type: FETCH_FAILED, error }));
  };
}

/**
 * Resume subscription thunk
 * @param {String} subscriptionName
 * @return {Function}
 */
export function resumeSubscription(subscriptionName) {
  return (dispatch) => {
    dispatch({ type: RESUME });
    return axios
      .patch('/api/subscription/resume', { subscriptionName })
      .then((response) => dispatch({ type: RESUME_SUCCESS, payload: response.data }))
      .catch((error) => dispatch({ type: RESUME_FAILED, error }));
  };
}

/**
 * Start free trial thunk
 * @param {Object} data
 * @return {Function}
 */
export function startFreeTrial(data) {
  return (dispatch) => {
    dispatch({ type: START_FREE_TRIAL });

    const paymentData = {
      ...(data.setupIntent ? payments.parseStripeToken(data.setupIntent) : null),
      pm_postal: data.pm_postal,
    };

    return axios
      .post('/api/subscription/trial', paymentData)
      .then((response) => dispatch({ type: START_FREE_TRIAL_SUCCESS, payload: response.data }))
      .catch((error) => {
        const payload = error.response.data.errors;
        return dispatch({ type: START_FREE_TRIAL_FAILED, error, payload });
      });
  };
}

/**
 * Update the user's subscription and/or subscribe to
 * a new subscription.
 * @param {Object} data
 * @return {Function}
 */
export function subscribe(data) {
  return (dispatch) => {
    dispatch({ type: SUBSCRIBE });

    const paymentData = {
      ...(data.setupIntent ? payments.parseStripeToken(data.setupIntent) : null),
      pm_postal: data.pm_postal,
    };

    return axios
      .post('/api/subscription', paymentData)
      .then((response) => dispatch({ type: SUBSCRIBE_SUCCESS, payload: response.data }))
      .catch((error) => {
        const payload = error.response.data.errors;
        return dispatch({ type: SUBSCRIBE_FAILED, error, payload });
      });
  };
}

/**
 * Subscribes user to an add-on.
 * @param {Object} price
 * @return {Function}
 */
export function purchaseAddon(price) {
  return (dispatch) => {
    dispatch({ type: SUBSCRIBE });

    return axios
      .post('/api/subscription/addon', price)
      .then((response) => dispatch({ type: SUBSCRIBE_SUCCESS, payload: response.data }))
      .catch((error) => {
        const payload = error.response.data.errors;
        return dispatch({ type: SUBSCRIBE_FAILED, error, payload });
      });
  };
}

/**
 * Switch Subscription Thunk
 * @param {Object} data
 * @return {Function}
 */
export function switchSubscription(data) {
  return (dispatch) => {
    dispatch({ type: SWITCH });
    return axios
      .patch('/api/subscription/switch', data)
      .then((response) => dispatch({ type: SWITCH_SUCCESS, payload: response.data }))
      .catch((error) => dispatch({ type: SWITCH_FAILED, error }));
  };
}

/**
 * Update User Thunk
 * @param {Object} user
 * @return {Function}
 */
export function update(userId, data) {
  return (dispatch) => {
    dispatch({ type: UPDATE });
    return axios
      .put(`/api/users/${userId}`, data)
      .then((response) => dispatch(updateSuccess(response)))
      .catch((error) => dispatch(updateFailed(error)));
  };
}

/**
 * User Answered Questionnaire Thunk
 * @param {Object} pass
 * @return {Function}
 */
export function answerQuestionnaire(userId, data) {
  return (dispatch) => {
    dispatch({ type: UPDATE });
    return axios
      .put(`/api/users/${userId}/questionnaire`, data)
      .then((response) => dispatch(updateSuccess(response)))
      .catch((error) => dispatch(updateFailed(error)));
  };
}

/**
 * Update User Password Thunk
 * @param {Object} pass
 * @return {Function}
 */
export function updatePassword(userId, pass) {
  return (dispatch) => {
    dispatch({ type: UPDATE });
    return axios
      .put(`/api/users/${userId}/password`, pass)
      .then((response) => dispatch(updateSuccess(response)))
      .catch((error) => dispatch(updateFailed(error)));
  };
}

/**
 * Update User Payment Card Thunk
 * @param {Object} card
 * @return {Function}
 */
export function updatePaymentCard(userId, data) {
  return (dispatch) => {
    dispatch({ type: UPDATE_PAYMENT });

    const card = {
      ...(data.setupIntent ? payments.parseStripeToken(data.setupIntent) : null),
      pm_postal: data.pm_postal,
    };

    return axios
      .put(`/api/users/${userId}/payment-card`, card)
      .then((response) => dispatch(updateSuccess(response)))
      .catch((error) => dispatch(updateFailed(error)));
  };
}

/**
 * Upload User Avatar Thnuk.
 * @param {Object} photo
 * @return {Function}
 */
export function uploadPhoto(userId, photo) {
  return (dispatch) => {
    const reader = new FileReader();
    reader.onload = (event) => dispatch({ type: UPLOAD, payload: event.target.result });
    reader.readAsDataURL(photo);

    const data = new FormData();
    data.append('avatar', photo);

    return axios
      .post(`/api/users/${userId}/avatar`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => dispatch({ type: UPLOAD_SUCCESS, payload: response }))
      .catch((error) => dispatch({ type: UPLOAD_FAILED, error }));
  };
}
